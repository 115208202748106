import React, { useState } from 'react';

import Layout from '../../../components/layout';
import LicensedIn from '../../../components/licensed-in';
import { graphql, useStaticQuery } from 'gatsby';

export default () => {
  const faq = useStaticQuery(graphql`
    query glossaryQuery {
      allContentfulGlossary(sort: { fields: [term] }) {
        nodes {
          description {
            childrenContentfulRichText {
              html
            }
          }
          term
        }
      }
    }
  `);

  const [licensedInOpened, setLicensedInOpened] = useState(false);
  const [getAQuoteOpened, setGetAQuoteOpened] = useState(false);

  const props = {
    licensedInOpened,
    setLicensedInOpened,
    getAQuoteOpened,
    setGetAQuoteOpened
  };

  console.log('faq', faq);
  return (
    <Layout activePage='resources' {...props}>
      <div className='resources-glossary-container'>
        <div className='resources-texts'>
          <div className='resources-texts-hero'>GLOSSARY</div>
        </div>
        {faq.allContentfulGlossary.nodes.map(g => {
          return (
            <div className='resources-glossary-terms'>
              <div className='resources-glossary-term'>{g.term}</div>

              {g.description.childrenContentfulRichText.map(c => (
                <div dangerouslySetInnerHTML={{ __html: c.html }} />
              ))}
            </div>
          );
        })}
      </div>
      <LicensedIn {...props} />
    </Layout>
  );
};
